import React from "react";
import Layout from "../../components/layout";
import HealthCare from "../../components/views/industries/health-care";

const HealthCarePage = () => {
  return (
    <Layout>
      <HealthCare />
    </Layout>
  );
};

export default HealthCarePage;
